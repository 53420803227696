import React from "react"
import Reusable from "../../components/articleLayout"
import BinanceLogo from "../../images/binance.jpg"
import BinanceCard from "../../images/TwitterCards/BinanceCard.png"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const BinanceUS15 = () => (
  <Layout>
    <SEO
      title="Earn 20% - 40% Commission on Binance.us"
      description="Learn how to earn 20% - 40% Commission on Binance.us"
      article={true}
      image={BinanceCard}
      pathname="/crypto/binance-us-comm"
    />
    <Reusable
      CoinbaseRef={false}
      Logo={BinanceLogo}
      ImageAlt="Binance Logo"
      From="www.binance.us"
      Title="Earn 20% - 40% Commission on Binance.us"
      Ticker="Commission"
      Value="20% - 40%"
      Address="https://accounts.binance.us/en/register?ref=35001636"
      Step1="Click the “Claim Now” button on this page."
      Step2="You will be redirected to Binance.us."
      Step3="Fill out your information and click Create Account."
      Step4="Check your email and click verify email address. (You will be redirected back to Binance.us)"
      Step5="Login into your account."
      Step6="Setup 2 Factor Authentication with Google Authentication or SMS Authentication. (This is standard practice to protect your account.)"
      Step7="Navigate to Settings/Basic Info under your profile."
      Step8="Complete Basic Verification. (Basic Identity and Address Information)"
      Step9="Complete Advanced Verification. (Proof of address and Government ID)"
      // Step10="Complete Fiat Account Verification. (Prime Trust Questionnaire)"
      Step10="Click on the “Refer” button on the top nav bar. (You will be redirected to the Referral Program Page.)"
      Step11="Provide all necessary information and register for the Referral Program."
      Step12="Share your invite link with all your friends. (Located on the Referral Program Page.)"
      Step13="Congratulations! Now sit back and watch your commissions roll in!"
      Introduction="If you are thinking about becoming a day trader with crypto, Binance.us is a great exchange to get your feet wet! Binance.us currently offers up to 48 trading pairs with 28 different coins to choose from. They also allow you to buy BTC, ETH, BNB, BCH, XRP and BUSD with your Checking Account or Debit Card within minutes of signing up. To make things even better, they offer a referral program to their users. When your friend signs up using your referral link, you will receive 20% commission on every single trade they make. In addition if you hold 500 BNB or more in your account, you will make a whopping 40% commission for every trade. Free crypto never felt so good! What are you waiting for?"
    />
  </Layout>
)

export default BinanceUS15
